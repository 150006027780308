import axios from '@/axios';

class GeneralSettingsService {
    index(query) {
        return axios.get(`/settings/settings`, {
            params: query
        });
    }
    update(data) {
        return axios.put(`/settings/settings`, data);
    }
}

export const generalSettingsService = new GeneralSettingsService();