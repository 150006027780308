<template>
    <div class="table-view general-settings-table-view">
        <div class="action-header">
            <h1>{{ $t('General settings') }}</h1>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">{{ $t('Setting') }}</th>
                    <th scope="col">{{ $t('Value') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ $t('Default language') }}</td>
                    <td>
                        <form>
                            <Multiselect
                                v-model="settings.default_language"
                                :searchable="true"
                                :options="languages"
                                @select="save('default_language')"
                                :placeholder="$t('Select language')"
                                class="form-control"
                            />
                        </form>
                    </td>
                </tr>
                <tr class="align-cols-top">
                    <td>{{ $t('Email template') }}</td>
                    <td>
                        <form @submit.prevent="save('email_template')">
                            <textarea class="form-control" v-model="settings.email_template" @change="save('email_template')"></textarea>
                        </form>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import _ from "lodash";
import {generalSettingsService} from '@/services/settings/generalSettingsService'
import Multiselect from '@vueform/multiselect'

export default {
    name: "ViewAllSettings",
    components: {
        Multiselect
    },
    data() {
        return {
            settings: {
                "email_template": null,
                default_language: null,
            },
        }
    },
    computed: {
        languages() {
            return this.$store.getters.languages
        }
    },
    mounted() {
        this.getSettings()
    },
    methods: {
        getSettings() {
            generalSettingsService.index({}).then(response => {
                this.settings = response.data.data
            })
        },
        save(key) {
            generalSettingsService.update({
                key: key,
                value: this.settings[key]
            }).then(response => {
                this.settings = response.data.data
            })
        },
        debounceGetEvents: _.debounce(function () {
            this.getSettings()
        }, 1000)
    }
}
</script>